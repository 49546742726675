
import { defineComponent, onMounted, ref } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import Entitytable from "@/components/EntityTable.vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import RequestPayoutModal from "@/components/RequestPayoutModal.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "payouts-details",
  components: {
    Entitytable,
    RequestPayoutModal,
  },
  methods: {
    catchErrors(error) {
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    updateTransaction(transaction_id, newStatus) {
      this.invoice = "";
      if (newStatus == "processed") {
        Swal.fire({
          title: "Payout Confirmation",
          text: "Please input invoice number to perform this operation.",
          input: "text",
          inputValue: this.invoice,
          customClass: {
            input: "form-control form-control-solid",
          },
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "Input a valid invoice number";
            }
          },
          preConfirm: (value) => {
            ApiService.put("transactions/" + transaction_id, {
              status: newStatus,
              invoice: value,
            })
              .then((response) => {
                this.refreshData();
              })
              .catch(this.catchErrors);
          },
        });
      } else {
        ApiService.put("transactions/" + transaction_id, {
          status: newStatus,
          invoice: null,
        })
          .then((response) => {
            this.refreshData();
          })
          .catch(this.catchErrors);
      }
    },
    refreshModal() {
      this.modal.show();
    },
    refreshData() {
      (this.$refs.txEntities as any).retrieveData();
    },
    resetData() {
      this.modalKey = Math.random().toString();
      alert(this.modalKey);
    },
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTime(date) {
      return moment(date).format("hh:mm:ss");
    },
  },
  data() {
    return {
      invoice: "",
      modalKey: "",
      balance: "Loading... ",
      filters: {
        type: "payout",
      },
      columnData: [
        {
          label: "ID",
          name: "id",
        },
        {
          label: "Payment Method",
          name: "payment_method",
        },
        {
          label: "Amount",
          name: "amount_string",
        },
        {
          label: "Status",
          name: "status_string",
        },
      ],
    };
  },
  mounted() {
    const element = document.getElementById(
      "request-payout-modal"
    ) as HTMLElement;
    this.modal = new Modal(element, {});

    ApiService.get("balance").then((result) => {
      this.balance = result.data.display;
    });
  },
  setup() {
    let modal = ref<Modal>();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Payouts", ["Payouts", "Details"]);
      setCurrentPageTitle("Payouts");
    });

    return { modal };
  },
});
